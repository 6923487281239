import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import contentClient from "../../config/Sporting";

export const getWho = createAsyncThunk("whoswho/getWho", async () => {
	try {
		const entries = await contentClient.getEntries({
			content_type: "whosWho",
			select: "fields",
		});
		return entries.items;
	} catch (error) {
		return null;
	}
});

export const getTrophy = createAsyncThunk("whoswho/getTrophy", async () => {
	try {
		const entries = await contentClient.getEntries({
			content_type: "trophy",
			select: "fields",
		});

		return entries.items;
	} catch (error) {
		return null;
	}
});

const FoundingSlice = createSlice({
	name: "founding",
	initialState: { whoswho: null, trophy: null },
	extraReducers: {
		// WHO'S WHOS
		[getWho.pending]: (state) => {
			state.whoswho = null;
		},
		[getWho.fulfilled]: (state, action) => {
			state.whoswho = action.payload;
		},
		[getWho.rejected]: (state) => {
			state.whoswho = null;
		},

		// TROPHY
		[getTrophy.pending]: (state) => {
			state.trophy = null;
		},
		[getTrophy.fulfilled]: (state, action) => {
			state.trophy = action.payload;
		},
		[getTrophy.rejected]: (state) => {
			state.trophy = null;
		},
	},
});

export const authActions = FoundingSlice.actions;
export default FoundingSlice;
