import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";
import contentClient from "../../config/Sporting";

export const getAllStore = createAsyncThunk("store/getAllStore", async () => {
	try {
		const entries = await contentClient.getEntries({
			content_type: "store",
			order: "fields.datePublished",
		});
		return entries.items?.sort(
			(a, b) =>
				moment(b?.fields?.datePublished) - moment(a?.fields?.datePublished)
		);
	} catch (error) {
		return null;
	}
});

export const getKitStore = createAsyncThunk(
	"store/getKitStore",
	async (kit) => {
		try {
			const entries = await contentClient.getEntries({
				content_type: "store",
				"fields.kit": kit,
				order: "-fields.datePublished",
			});
			return entries.items;
		} catch (error) {
			return null;
		}
	}
);
export const getStorePromotion = createAsyncThunk(
	"store/getStorePromotion",
	async () => {
		try {
			const entries = await contentClient.getEntries({
				content_type: "storePromotion",
			});
			return entries.items;
		} catch (error) {
			return null;
		}
	}
);

export const getLandingStore = createAsyncThunk(
	"store/getLandingStore",
	async () => {
		try {
			const entries = await contentClient.getEntries({
				content_type: "store",
				order: "fields.datePublished",
				limit: 6,
			});
			return entries.items;
		} catch (error) {
			return null;
		}
	}
);

export const getSingleStore = createAsyncThunk(
	"store/getSingleStore",
	async (id) => {
		try {
			const entries = await contentClient.getEntry(id);
			return entries;
		} catch (error) {
			return null;
		}
	}
);

export const getSingleStorePromotion = createAsyncThunk(
	"store/getSingleStorePromotion",
	async (id) => {
		try {
			const entries = await contentClient.getEntry(id);
			return entries;
		} catch (error) {
			return null;
		}
	}
);

const StoreSlice = createSlice({
	name: "store",
	initialState: {
		allStore: null,
		landingStore: null,
		kitStore: null,
		singleStore: null,
		storePromotion: null,
		singleStorePromtion: null,
	},
	extraReducers: {
		// ALL STORE
		[getAllStore.pending]: (state) => {
			state.allStore = null;
		},
		[getAllStore.fulfilled]: (state, action) => {
			state.allStore = action.payload;
		},
		[getAllStore.rejected]: (state) => {
			state.allStore = null;
		},

		// STORE PROMOTION
		[getStorePromotion.pending]: (state) => {
			state.storePromotion = null;
		},
		[getStorePromotion.fulfilled]: (state, action) => {
			state.storePromotion = action.payload;
		},
		[getStorePromotion.rejected]: (state) => {
			state.storePromotion = null;
		},

		// SINGLE STORE PROMOTION
		[getSingleStorePromotion.pending]: (state) => {
			state.singleStorePromtion = null;
		},
		[getSingleStorePromotion.fulfilled]: (state, action) => {
			state.singleStorePromtion = action.payload;
		},
		[getSingleStorePromotion.rejected]: (state) => {
			state.singleStorePromtion = null;
		},

		// LANDING STORE
		[getLandingStore.pending]: (state) => {
			state.landingStore = null;
		},
		[getLandingStore.fulfilled]: (state, action) => {
			state.landingStore = action.payload;
		},
		[getLandingStore.rejected]: (state) => {
			state.landingStore = null;
		},
		// STORE KIT
		[getKitStore.pending]: (state) => {
			state.kitStore = null;
		},
		[getKitStore.fulfilled]: (state, action) => {
			state.kitStore = action.payload;
		},
		[getKitStore.rejected]: (state) => {
			state.kitStore = null;
		},

		// SINGLE STORE
		[getSingleStore.pending]: (state) => {
			state.singleStore = null;
		},
		[getSingleStore.fulfilled]: (state, action) => {
			state.singleStore = action.payload;
		},
		[getSingleStore.rejected]: (state) => {
			state.singleStore = null;
		},
	},
});

export const authActions = StoreSlice.actions;
export default StoreSlice;
