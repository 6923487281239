import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import contentClient from "../../config/Sporting";

export const getJobs = createAsyncThunk("sportingJobs/getJobs", async () => {
	try {
		const entries = await contentClient.getEntries({
			content_type: "sportingJobs",
			select: "fields",
		});
		return entries.items;
	} catch (error) {
		return null;
	}
});

export const getSingleJob = createAsyncThunk(
	"sportingJobs/getSingleJob",
	async (id) => {
		try {
			const entries = await contentClient.getEntry(id);
			return entries;
		} catch (error) {
			return null;
		}
	}
);

const JobSlice = createSlice({
	name: "job",
	initialState: { jobs: null, singleJob: null },
	extraReducers: {
		// ALL JOBS
		[getJobs.pending]: (state) => {
			state.jobs = null;
		},
		[getJobs.fulfilled]: (state, action) => {
			state.jobs = action.payload;
		},
		[getJobs.rejected]: (state) => {
			state.jobs = null;
		},

		// SINGLE JOB
		[getSingleJob.pending]: (state) => {
			state.singleJob = null;
		},
		[getSingleJob.fulfilled]: (state, action) => {
			state.singleJob = action.payload;
		},
		[getSingleJob.rejected]: (state) => {
			state.singleJob = null;
		},
	},
});

export const authActions = JobSlice.actions;
export default JobSlice;
