import React, { useState, useRef } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

import searchIcon from "../assets/images/search-lg.svg";
// import userIcon from "../assets/images/user-01.svg";
import { useEffect } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
// import { useDispatch, useSelector } from "react-redux";
// import { onAuthStateChanged } from "firebase/auth";
// import { auth, db } from "../config/fire";
// import { userLogin, userData } from "../store/slices/AuthSlice";
// import { doc, onSnapshot } from "firebase/firestore";
import hamburger from "../assets/images/hamburger.svg";
import close from "../assets/images/x-close.svg";
import { toast } from "react-toastify";

const Header = ({
  setOpenSearch,
  openSearch,
  setSearch,
  search,
  openSidebar,
  setOpenSidebar,
  error,
  sideInputRef,
}) => {
  const location = useLocation();
  const { playerID, kit, searchData } = useParams();
  const [sub, setSub] = useState(false);
  const [sub2, setSub2] = useState(false);
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const [scrolled, setScrolled] = useState(false);
  const headRef = useRef();
  // const dispatch = useDispatch();
  // const { isLoggedin, user } = useSelector((auth) => auth.auth);

  const setScroll = () => {
    const offset = window.scrollY;

    if (offset > 70) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  const handleSearch = () => {
    setOpenSearch(true);
    if (openSearch) {
      if (search?.length <= 0) {
        setOpenSearch(!openSearch);
        return;
      } else if (search?.length > 2) {
        setOpenSearch(false);
        navigate(`/search/${search}`);
      } else {
        toast.info("Search Parameter must be 3 or more characters");
      }
    }
  };
  // useEffect(() => {
  // 	onAuthStateChanged(auth, async (user) => {
  // 		if (user?.emailVerified) {
  // 			dispatch(userLogin());
  // 			onSnapshot(doc(db, "users", `${user.email}`), (doc) => {
  // 				if (doc.exists()) {
  // 					dispatch(userData({ ...doc.data(), uid: auth?.currentUser?.uid }));
  // 				}
  // 			});
  // 		}
  // 	});
  // }, [isLoggedin, dispatch]);
  useEffect(() => {
    window.addEventListener("scroll", setScroll);
    (playerID !== undefined && playerID !== null) ||
    location.pathname.match("profile") ||
    error ||
    (kit !== undefined && kit !== null) ||
    (searchData !== undefined && searchData !== null)
      ? (headRef.current.style.backgroundColor = "#202e55") &&
        window.innerWidth > 760
        ? (headRef.current.style.height = "80px")
        : (headRef.current.style.height = "48px")
      : scrolled
      ? (headRef.current.style.backgroundColor = "#202e55") &&
        window.innerWidth > 760
        ? (headRef.current.style.height = "80px")
        : (headRef.current.style.height = "48px")
      : !openSearch &&
        (headRef.current.style.backgroundColor = "transparent") &&
        (headRef.current.style.height = 0);

    return () => {
      window.removeEventListener("scroll", setScroll);
    };
  }, [scrolled, openSearch, playerID, kit, error, location, searchData]);

  return (
    <div ref={headRef} className="header">
      <div className="header-content">
        <div className="menu">
          <img
            src={!openSidebar ? hamburger : close}
            alt=""
            onClick={() => setOpenSidebar(!openSidebar)}
          />
        </div>

        <div
          className="logo"
          onClick={() => {
            setSub(false);
            setOpenSidebar(false);
          }}
        >
          <Link to={"/"}>
            <img
              src="https://res.cloudinary.com/dok9baj3l/image/upload/v1664677918/sporting-lagos/sporting-logo_biealj.svg"
              // src="https://res.cloudinary.com/dok9baj3l/image/upload/v1677926431/sporting-lagos/sporting-logo-old_heijpx.svg"
              alt="logo"
            />
          </Link>
        </div>

        <nav className="nav-container">
          <div className="link" onClick={() => setSub(false)}>
            <Link to={"news"}>NEWS</Link>
          </div>
          <div
            onMouseOver={() => setSub2(true)}
            onClick={() => setSub2(!sub)}
            className="link more2"
          >
            {/* <Link to={"matches"}>MATCHES</Link> */}
            <div>
              <div>MATCHES</div>
              {sub2 ? (
                <FaChevronUp className="chevron" />
              ) : (
                <FaChevronDown className="chevron" />
              )}
            </div>
            {sub2 && (
              <div onMouseLeave={() => setSub2(false)} className="sub_overlay">
                <div className="sub_link">
                  <div className="link" onClick={() => setSub2(!sub)}>
                    <Link to={"matches"}>FIXTURES</Link>
                  </div>

                  <div className="link" onClick={() => setSub2(!sub)}>
                    <Link to={"matches&table&all"}>TABLE</Link>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="link" onClick={() => setSub(false)}>
            <Link to={"players"}>PLAYERS</Link>
          </div>
          <div className="link" onClick={() => setSub(!sub)}>
            <Link to={"shop"}>SHOP</Link>
          </div>
          {/* <div className="link" onClick={() => setSub(!sub)}>
            <Link to={"membership"}>MEMBERSHIP</Link>
          </div> */}
          {/* <div className="link" onClick={() => setSub(!sub)}>
            <Link to={"junioracademy"}>JR ACADEMY</Link>
          </div> */}
          <div className="link" onClick={() => setSub(!sub)}>
            <Link to={"academy"}>ACADEMY</Link>
          </div>
          <div className="link" onClick={() => setSub(!sub)}>
            <Link to={"club-info"}>CLUB INFO</Link>
          </div>
          <div className="link" onClick={() => setSub(!sub)}>
            <Link to={"sportingtv"}>SPORTING TV</Link>
          </div>
          {/* <div className="link" onClick={() => setSub(false)}>
						<Link to={"tickets"}>TICKETS</Link>
					</div> */}
          {/* <div
						onMouseOver={() => setSub(true)}
						onClick={() => setSub(!sub)}
						className="link more"
					>
						<div>
							<div>MORE</div>
							{sub ? (
								<FaChevronUp className="chevron" />
							) : (
								<FaChevronDown className="chevron" />
							)}
						</div>
						{sub && (
							<div onMouseLeave={() => setSub(false)} className="sub_overlay">
								<div className="sub_link">
									<div className="link" onClick={() => setSub(!sub)}>
										<Link to={"shop"}>SHOP</Link>
									</div>
									{/* <div className="link" onClick={() => setSub(!sub)}>
										<Link to={"club-info/foundingmembers"}>
											BECOME A FOUNDING MEMBER
										</Link>
									</div> */}
          {/* <div className="link" onClick={() => setSub(!sub)}>
										<Link to={"club-info"}>CLUB INFORMATION</Link>
									</div>
									<div className="link" onClick={() => setSub(!sub)}>
										<Link to={"sportingtv"}>SPORTING TV</Link>
									</div>
								</div>
							</div>
						)}
					</div> */}
        </nav>

        <div className="other-items">
          <div className="right-items">
            <div
              className="search mobile"
              onClick={() => {
                if (!location.pathname.includes("search")) {
                  if (openSidebar) {
                    setOpenSidebar(false);
                    sideInputRef.current.blur();
                    return;
                  }
                  setOpenSidebar(true);
                  sideInputRef.current.focus();
                }
              }}
            >
              <img src={searchIcon} alt="saerch-icon" />
            </div>
            <div
              className="search"
              onMouseOver={() => {
                !location.pathname.includes("search") && setOpenSearch(true);
                inputRef.current.focus();
              }}
              onMouseLeave={() => {
                setOpenSearch(false);
                inputRef.current.blur();
              }}
            >
              <img src={searchIcon} alt="search-icon" />
              <div
                className={`search-input ${!openSearch ? "hide_search" : ""}`}
              >
                <div
                  className={`search_input ${
                    openSearch ? "delay" : "delay_non"
                  }`}
                >
                  <input
                    ref={inputRef}
                    type="text"
                    placeholder="Search Sporting"
                    value={search}
                    onChange={(e) => setSearch(e?.target?.value)}
                    className="input"
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleSearch();
                      }
                    }}
                  />
                  {search?.length > 0 && (
                    <div className="close" onClick={() => setSearch("")}>
                      <img src={close} alt="" />
                    </div>
                  )}
                </div>
                <div
                  onClick={handleSearch}
                  className={`search_button ${
                    openSearch ? "delay" : "delay_non"
                  }`}
                >
                  <p>Search</p>
                </div>
              </div>
            </div>
            {/* {isLoggedin && (
							<>
								{user && (
									<div
										className="user"
										onClick={() => {
											navigate("/profile");
											setOpenSidebar(false);
										}}
									>
										{user?.avatar !== "" && (
											<img className="avatar" src={user?.avatar} alt="avatar" />
										)}
										{user?.avatar === "" && (
											<div className="avatar2 text">
												<p>{user?.fullname[0]}</p>
											</div>
										)}
									</div>
								)}
							</>
						)}
						{!isLoggedin && (
							<div
								className="user"
								onClick={() => {
									navigate("/signin");
									setOpenSidebar(false);
								}}
							>
								<div className="sgn">
									<img src={userIcon} alt="" />
									<span>&nbsp;Sign in</span>
								</div>
							</div>
						)} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
