import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import contentClient from "../../config/Sporting";

export const getTotalGamesWon = createAsyncThunk(
	"footer/getTotalGamesWon",
	async () => {
		try {
			const entries = await contentClient.getEntries({
				content_type: "leagueTable",
				select: "fields",
			});
			let count = 0;
			entries?.items?.map((item) => {
				count += Number(
					item?.fields?.leagueTable?.filter(
						(l) => l?.name?.toLowerCase() === "sporting lagos"
					)[0]?.matches_won
				);
				return 0;
			});
			return count;
		} catch (error) {
			return null;
		}
	}
);
export const getTotalGoals = createAsyncThunk(
	"footer/getTotalGoals",
	async () => {
		try {
			const entries = await contentClient.getEntries({
				content_type: "matches",
				order: "fields.matchDate",
			});
			let count = 0;
			entries?.items?.map((item) => {
				item?.fields?.matchDetails
					? (count += Number(item?.fields?.matchDetails?.scores?.sporting))
					: (count += 0);

				return 0;
			});
			return count;
		} catch (error) {
			return null;
		}
	}
);

const FooterSlice = createSlice({
	name: "footer",
	initialState: { gm: null, gl: null },
	extraReducers: {
		// GW
		[getTotalGamesWon.pending]: (state) => {
			state.gw = null;
		},
		[getTotalGamesWon.fulfilled]: (state, action) => {
			state.gw = action.payload;
		},
		[getTotalGamesWon.rejected]: (state) => {
			state.gw = null;
		},
		// GL
		[getTotalGoals.pending]: (state) => {
			state.gl = null;
		},
		[getTotalGoals.fulfilled]: (state, action) => {
			state.gl = action.payload;
		},
		[getTotalGoals.rejected]: (state) => {
			state.gl = null;
		},
	},
});

export const authActions = FooterSlice.actions;
export default FooterSlice;
