import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";
import contentClient from "../../config/Sporting";

export const getAllMatches = createAsyncThunk(
  "matches/getAllMatches",
  async () => {
    try {
      const entries = await contentClient.getEntries({
        content_type: "matches",
        order: "fields.matchDate",
      });
      return entries?.items;
    } catch (error) {
      return null;
    }
  }
);
export const getMatchNews = createAsyncThunk(
  "news/getMatchNews",
  async (tag) => {
    try {
      const entries = await contentClient.getEntries({
        "sys.contentType.sys.id[in]": "gallery,news,sportingTv",
        "metadata.tags.sys.id[in]": tag,
        limit: 3,
      });

      return entries.items;
    } catch (error) {
      return null;
    }
  }
);

export const getLandingMatches = createAsyncThunk(
  "matches/getLandingMatches",
  async () => {
    try {
      const entries = await contentClient.getEntries({
        content_type: "matches",
        "fields.team": "first",
        order: "fields.matchDate",
      });

      const less = entries?.items
        ?.filter((match) => moment(match?.fields?.matchDate) < moment())
        .sort(
          (a, b) => moment(b?.fields?.matchDate) - moment(a?.fields?.matchDate)
        )
        .slice(0, 2);

      const more = entries?.items
        ?.filter((match) => moment(match?.fields?.matchDate) > moment())
        .slice(0, 3);
      const filteredEntries = less.reverse().concat(more);

      return filteredEntries;
    } catch (error) {
      return null;
    }
  }
);

export const getSingleMatch = createAsyncThunk(
  "matches/getSingleMatch",
  async (id) => {
    try {
      const entries = await contentClient.getEntry(id);
      return entries;
    } catch (error) {
      return null;
    }
  }
);

const MatchSlice = createSlice({
  name: "matches",
  initialState: {
    allMatches: null,
    landingMatches: [],
    singleMatch: null,
    matchNews: null,
  },
  extraReducers: {
    // ALL MATCHES
    [getAllMatches.pending]: (state) => {
      state.allMatches = null;
    },
    [getAllMatches.fulfilled]: (state, action) => {
      state.allMatches = action.payload;
    },
    [getAllMatches.rejected]: (state) => {
      state.allMatches = null;
    },
    // LANDING MATCHES
    [getLandingMatches.pending]: (state) => {
      state.landingMatches = null;
    },
    [getLandingMatches.fulfilled]: (state, action) => {
      state.landingMatches = action.payload;
    },
    [getLandingMatches.rejected]: (state) => {
      state.landingMatches = null;
    },

    // SINGLE MATCH
    [getSingleMatch.pending]: (state) => {
      state.singleMatch = null;
    },
    [getSingleMatch.fulfilled]: (state, action) => {
      state.singleMatch = action.payload;
    },
    [getSingleMatch.rejected]: (state) => {
      state.singleMatch = null;
    },

    // MATCH NEWS
    [getMatchNews.pending]: (state) => {
      state.matchNews = null;
    },
    [getMatchNews.fulfilled]: (state, action) => {
      state.matchNews = action.payload;
    },
    [getMatchNews.rejected]: (state) => {
      state.matchNews = null;
    },
  },
});

export const authActions = MatchSlice.actions;
export default MatchSlice;
