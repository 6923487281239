const ScrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

export default ScrollToTop;

export const ScrollToTopSnap = (num) => {
  window.scrollTo({
    top: 0 + num,
  });
};

export const ScrollToDiv = (ref) => {
  window.scrollTo({
    top: ref?.current?.offsetTop - 100,
    behavior: "smooth",
  });
};
export const ScrollToDivNews = () => {
  document
    .querySelector(".scroll")
    ?.scrollIntoView({ behavior: "smooth", block: "start", inline: "start" });
};
export const ScrollToDivSearch = () => {
  document
    .querySelector(".scroll_search")
    ?.scrollIntoView({ behavior: "smooth", block: "start", inline: "start" });
};
export const ScrollToDivSnap = (ref) => {
  window.scrollTo({
    top: ref?.current?.offsetTop + 200,
  });
};

export const ScrollToDivSnap2 = (ref) => {
  window.scrollTo({
    top: ref?.current?.offsetTop - 400,
  });
};
