import { configureStore } from "@reduxjs/toolkit";
import AchieveSlice from "./slices/AchieveSlice";
import authSlice from "./slices/AuthSlice";
import FooterSlice from "./slices/FooterSlice";
import ClubInfoSlice from "./slices/ClubInfoSlice";
import HistorySlice from "./slices/HistorySlice";
import JobSlice from "./slices/JobSlice";
import LeagueSlice from "./slices/LeagueSlice";
import MatchSlice from "./slices/MatchSlice";
import NewsSlice from "./slices/NewsSlice";
import PartnerSlice from "./slices/PartnerSlice";
import PartnerLowerSlice from "./slices/PartnerLowerSlice";
import PlayerSlice from "./slices/PlayerSlice";
import PromotionSlice from "./slices/PromotionSlice";
import TvSlice from "./slices/TvSlice";
import StoreSlice from "./slices/StoreSlice";
import Socialslice from "./slices/Socialslice";
import SearchSlice from "./slices/SearchSlice";

const store = configureStore({
	reducer: {
		auth: authSlice.reducer,
		news: NewsSlice.reducer,
		matches: MatchSlice.reducer,
		achieve: AchieveSlice.reducer,
		promotion: PromotionSlice.reducer,
		player: PlayerSlice.reducer,
		partner: PartnerSlice.reducer,
		partnerLower: PartnerLowerSlice.reducer,
		foundingMembers: ClubInfoSlice.reducer,
		jobs: JobSlice.reducer,
		history: HistorySlice.reducer,
		league: LeagueSlice.reducer,
		footer: FooterSlice.reducer,
		sportingTv: TvSlice.reducer,
		sportingStore: StoreSlice.reducer,
		social: Socialslice.reducer,
		search: SearchSlice.reducer,
	},
});
export default store;
