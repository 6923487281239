import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import contentClient from "../../config/Sporting";

export const getHistory = createAsyncThunk(
	"sportingHistory/getHistory",
	async () => {
		try {
			const entries = await contentClient.getEntries({
				content_type: "sportingHistory",
				select: "fields",
			});
			return entries.items;
		} catch (error) {
			return null;
		}
	}
);

const HistorySlice = createSlice({
	name: "history",
	initialState: { history: null },
	extraReducers: {
		[getHistory.pending]: (state) => {
			state.history = null;
		},
		[getHistory.fulfilled]: (state, action) => {
			state.history = action.payload;
		},
		[getHistory.rejected]: (state) => {
			state.history = null;
		},
	},
});

export const authActions = HistorySlice.actions;
export default HistorySlice;
