import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import contentClient from "../../config/Sporting";

export const getAllTv = createAsyncThunk("sportingTv/getAllTv", async () => {
	try {
		const entries = await contentClient.getEntries({
			content_type: "sportingTv",
			order: "-fields.date",
		});
		return entries.items;
	} catch (error) {
		return null;
	}
});

export const getLandingTv = createAsyncThunk(
	"sportingTv/getLandingTv",
	async () => {
		try {
			const entries = await contentClient.getEntries({
				content_type: "sportingTv",
				order: "-fields.date",
				limit: 6,
			});
			return entries.items;
		} catch (error) {
			return null;
		}
	}
);

export const getTvCategory = createAsyncThunk(
	"sportingTv/getTvCategory",
	async (name) => {
		try {
			const entries = await contentClient.getEntries({
				content_type: "sportingTv",
				"fields.category": name,
				order: "-fields.date",
			});
			return entries.items;
		} catch (error) {
			return null;
		}
	}
);

export const getSingleTv = createAsyncThunk(
	"sportingTv/getSingleTv",
	async (id) => {
		try {
			const entries = await contentClient.getEntry(id);
			return entries;
		} catch (error) {
			return null;
		}
	}
);

const TvSlice = createSlice({
	name: "sportingTv",
	initialState: {
		allTv: null,
		landingTv: null,
		categoryTv: null,
		singleTv: null,
	},
	extraReducers: {
		// ALL TV
		[getAllTv.pending]: (state) => {
			state.allTv = null;
		},
		[getAllTv.fulfilled]: (state, action) => {
			state.allTv = action.payload;
		},
		[getAllTv.rejected]: (state) => {
			state.allTv = null;
		},
		// LANDING TV
		[getLandingTv.pending]: (state) => {
			state.landingTv = null;
		},
		[getLandingTv.fulfilled]: (state, action) => {
			state.landingTv = action.payload;
		},
		[getLandingTv.rejected]: (state) => {
			state.landingTv = null;
		},

		// SINGLE TV
		[getSingleTv.pending]: (state) => {
			state.singleTv = null;
		},
		[getSingleTv.fulfilled]: (state, action) => {
			state.singleTv = action.payload;
		},
		[getSingleTv.rejected]: (state) => {
			state.singleTv = null;
		},
		// CATEGORY TV
		[getTvCategory.pending]: (state) => {
			state.categoryTv = null;
		},
		[getTvCategory.fulfilled]: (state, action) => {
			state.categoryTv = action.payload;
		},
		[getTvCategory.rejected]: (state) => {
			state.categoryTv = null;
		},
	},
});

export const authActions = TvSlice.actions;
export default TvSlice;
