import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import contentClient from "../../config/Sporting";

export const getPromotion = createAsyncThunk(
	"promotion/getPromotion",
	async () => {
		try {
			const entries = await contentClient.getEntries({
				content_type: "promotion",
				select: "fields",
				order: "-fields.title",
			});
			return entries?.items;
		} catch (error) {
			return null;
		}
	}
);

const PromotionSlice = createSlice({
	name: "promotion",
	initialState: { promotion: null },
	extraReducers: {
		[getPromotion.pending]: (state) => {
			state.promotion = null;
		},
		[getPromotion.fulfilled]: (state, action) => {
			state.promotion = action.payload;
		},
		[getPromotion.rejected]: (state) => {
			state.promotion = null;
		},
	},
});

export const authActions = PromotionSlice.actions;
export default PromotionSlice;
