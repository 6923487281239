import React, { useRef } from "react";
import { useState } from "react";
import { Outlet, useParams } from "react-router-dom";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";

const Layout = ({ error }) => {
	const { videoID } = useParams();
	const [openSidebar, setOpenSidebar] = useState(false);
	const [openSearch, setOpenSearch] = useState(false);
	const [search, setSearch] = useState("");
	const sideInputRef = useRef(null);
	return (
		<div>
			<Header
				search={search}
				setSearch={setSearch}
				openSearch={openSearch}
				setOpenSearch={setOpenSearch}
				openSidebar={openSidebar}
				setOpenSidebar={setOpenSidebar}
				error={error}
				sideInputRef={sideInputRef}
			/>
			<Outlet context={[search, setSearch]} />
			<Sidebar
				sideInputRef={sideInputRef}
				search={search}
				setSearch={setSearch}
				openSidebar={openSidebar}
				setOpenSidebar={setOpenSidebar}
			/>
			{!videoID && <Footer />}
		</div>
	);
};
export default Layout;
