import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPartner } from "../store/slices/PartnerSlice";
import PartnerLower from "../components/PartnerLower";

const Partner = () => {
  const dispatch = useDispatch();
  const { partner } = useSelector((partner) => partner.partner);
  useEffect(() => {
    dispatch(getPartner());
  }, [dispatch]);

  return (
    <>
      {partner && (
        <div className="partner">
          <h1>PRINCIPAL CLUB PARTNERS</h1>
          <div className="top">
            {partner?.map((pat) => (
              <div className="img" key={pat?.sys?.id}>
                <a
                  target={"_blank"}
                  rel="noreferrer"
                  href={pat?.fields?.website}
                >
                  <img
                    src={pat?.fields?.logo?.fields?.file?.url}
                    alt={pat?.fields?.logo?.fields?.file?.fileName}
                  />
                </a>
              </div>
            ))}
          </div>
          {/* <div className="bottom">
            <div className="img">
              <img
                src="https://res.cloudinary.com/dok9baj3l/image/upload/v1664677918/sporting-lagos/sporting-logo_biealj.svg"
                alt="icon3"
              />
            </div>
          </div> */}
          <PartnerLower />
        </div>
      )}
    </>
  );
};

export default Partner;
