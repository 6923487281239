// import { collection, onSnapshot, query } from "firebase/firestore";
import React from "react";
// import { useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
// import Achieve from "../components/achieve/Achieve";
import Partner from "../components/Partner";
// import PartnerLower from "../components/PartnerLower";
import ScrollToTop from "../components/ScrollToTop";
// import { db } from "../config/fire";
// import { getTotalGamesWon, getTotalGoals } from "../store/slices/FooterSlice";

const Footer = () => {
  // const dispatch = useDispatch();
  // const { gw, gl } = useSelector((footer) => footer.footer);
  const location = useLocation();

  // const [foundingMembers, setFoundingMembers] = useState(null);
  // useEffect(() => {
  // 	dispatch(getTotalGamesWon());
  // 	dispatch(getTotalGoals());

  // 	const qq = query(collection(db, "foundingMember"));
  // 	onSnapshot(qq, (querySnapshot) => {
  // 		let data = [];
  // 		querySnapshot.forEach((doc) => {
  // 			data.push(doc.data());
  // 		});
  // 		setFoundingMembers(data);
  // 	});
  // }, [dispatch]);
  const except = [
    "/signin",
    "/signup",
    "/signin/",
    "/signup/",
    "/email-verify",
    "/email-verify/",
    "/sp",
  ];

  return (
    <>
      {!except?.includes(location?.pathname) && (
        <>
          {/* {gl && gw && foundingMembers && (
						<Achieve
							gw={gw}
							gl={gl}
							fm={foundingMembers ? foundingMembers.length : 0}
						/>
					)} */}
          <Partner />
          {/* <PartnerLower /> */}
        </>
      )}
      <div className="footer">
        <div className="upper-links">
          <div className="social-links">
            <a
              target={"_blank"}
              rel={"noreferrer"}
              href="https://twitter.com/SportingLagos"
            >
              <img
                src="https://res.cloudinary.com/dok9baj3l/image/upload/v1665070234/sporting-lagos/twitter-logo_gp5fod.svg"
                alt="@SportingLagos"
              />
            </a>

            <a
              target={"_blank"}
              rel={"noreferrer"}
              href="https://www.instagram.com/sportinglagos/"
            >
              <img
                src="https://res.cloudinary.com/dok9baj3l/image/upload/v1665070233/sporting-lagos/facebook-logo_munncn.svg"
                alt="@sportinglagos"
              />
            </a>
          </div>
          <div className="back-to-top">
            <div onClick={() => ScrollToTop()} href="we">
              <p>Back to Top↑</p>
            </div>
          </div>
        </div>
        <div className="lower-links">
          <div className="footer-menu">
            <ul>
              <li>
                <a href=".">
                  <p>Privacy Policy</p>
                </a>
              </li>

              <li>
                <Link to="/club-info/careers">
                  <p>Jobs &amp; Careers</p>
                </Link>
              </li>

              <li>
                <a
                  href="mailto:info@sportinglagos.com"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <p>Help &amp; Support</p>
                </a>
              </li>

              <li>
                <a href=".">
                  <p>Terms of Use</p>
                </a>
              </li>
            </ul>
          </div>

          <div className="copyright">
            {/* <p>Sporting Lagos 2022</p> */}
            <p>&copy; Sporting Lagos {new Date().getFullYear()}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
