import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import contentClient from "../../config/Sporting";

export const getLeague = createAsyncThunk("leagueTable/getLeague", async () => {
	try {
		const entries = await contentClient.getEntries({
			content_type: "leagueTable",
			select: "fields",
		});
		return entries.items;
	} catch (error) {
		return null;
	}
});

export const getLandingLeague = createAsyncThunk(
	"leagueTable/getLandingLeague",
	async () => {
		try {
			const entries = await contentClient.getEntries({
				content_type: "leagueTable",
				select: "fields",
				"fields.active[in]": "Active 1,Active 2",
			});

			return entries.items;
		} catch (error) {
			return null;
		}
	}
);

const LeagueSlice = createSlice({
	name: "league",
	initialState: { league: null, landingLeague: null },
	extraReducers: {
		// ALL LEAGUE
		[getLeague.pending]: (state) => {
			state.league = null;
		},
		[getLeague.fulfilled]: (state, action) => {
			state.league = action.payload;
		},
		[getLeague.rejected]: (state) => {
			state.league = null;
		},
		// LANDING LEAGUE
		[getLandingLeague.pending]: (state) => {
			state.landingLeague = null;
		},
		[getLandingLeague.fulfilled]: (state, action) => {
			state.landingLeague = action.payload;
		},
		[getLandingLeague.rejected]: (state) => {
			state.landingLeague = null;
		},
	},
});

export const authActions = LeagueSlice.actions;
export default LeagueSlice;
