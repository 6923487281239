import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import contentClient from "../../config/Sporting";

export const getLandingPlayer = createAsyncThunk(
	"player/getLandingPlayer",
	async () => {
		try {
			const entries = await contentClient.getEntries({
				content_type: "player",
				select: "fields",
				limit: 20,
			});
			return entries;
		} catch (error) {
			return null;
		}
	}
);

export const getMorePlayer = createAsyncThunk(
	"player/getMorePlayer",
	async (pos) => {
		try {
			const entries = await contentClient.getEntries({
				content_type: "player",
				select: "fields",
				"fields.position": `${pos}`,
				limit: 6,
			});
			return entries;
		} catch (error) {
			return null;
		}
	}
);

export const getAllPlayers = createAsyncThunk(
	"player/getAllPlayers",
	async () => {
		try {
			const entries = await contentClient.getEntries({
				content_type: "player",
				select: "fields",
			});
			return entries?.items;
		} catch (error) {
			return null;
		}
	}
);

export const getSinglePlayer = createAsyncThunk(
	"player/getSinglePlayer",
	async (id) => {
		try {
			const entries = await contentClient.getEntry(id);
			return entries?.fields;
		} catch (error) {
			return null;
		}
	}
);

const PlayerSlice = createSlice({
	name: "player",
	initialState: {
		landingPlayer: null,
		allPlayers: null,
		singlePlayer: null,
		morePlayer: null,
	},
	extraReducers: {
		// LANDING PLAYER
		[getLandingPlayer.pending]: (state) => {
			state.landingPlayer = null;
		},
		[getLandingPlayer.fulfilled]: (state, action) => {
			state.landingPlayer = action.payload;
		},
		[getLandingPlayer.rejected]: (state) => {
			state.landingPlayer = null;
		},
		// ALL PLAYER
		[getAllPlayers.pending]: (state) => {
			state.allPlayers = null;
		},
		[getAllPlayers.fulfilled]: (state, action) => {
			state.allPlayers = action.payload;
		},
		[getAllPlayers.rejected]: (state) => {
			state.allPlayers = null;
		},
		// SINGLE PLAYER
		[getSinglePlayer.pending]: (state) => {
			state.singlePlayer = null;
		},
		[getSinglePlayer.fulfilled]: (state, action) => {
			state.singlePlayer = action.payload;
		},
		[getSinglePlayer.rejected]: (state) => {
			state.singlePlayer = null;
		},
		// MORE PLAYER
		[getMorePlayer.pending]: (state) => {
			state.morePlayer = null;
		},
		[getMorePlayer.fulfilled]: (state, action) => {
			state.morePlayer = action.payload;
		},
		[getMorePlayer.rejected]: (state) => {
			state.morePlayer = null;
		},
	},
});

export const authActions = PlayerSlice.actions;
export default PlayerSlice;
