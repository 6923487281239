import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPartnerLower } from "../store/slices/PartnerLowerSlice"; // Adjust path as needed

const PartnerLower = () => {
    const dispatch = useDispatch();
    const partnerLower = useSelector((state) => state.partnerLower.partnerLower); // Adjust selector

    useEffect(() => {
        dispatch(getPartnerLower());
    }, [dispatch]);

    return (
        <>
            {partnerLower && (
                <div className="partner-lower"> {/* Updated class name */}
                    <div className="top">
                        {partnerLower.map((pat) => (
                            <div className="img" key={pat.sys.id}>
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href={pat.fields.website}
                                >
                                    <img
                                        src={pat.fields.logo.fields.file.url}
                                        alt={pat.fields.logo.fields.file.fileName}
                                    />
                                </a>
                            </div>
                        ))}
                    </div>
                    <div className="bottom">
                        <div className="img">
                            <img
                                src="https://res.cloudinary.com/dok9baj3l/image/upload/v1664677918/sporting-lagos/sporting-logo_biealj.svg"
                                alt="icon3"
                            />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default PartnerLower;