import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import contentClient from "../../config/Sporting";

// Fetch all partner lower entries
export const getPartnerLower = createAsyncThunk("partnerLower/getPartnerLower", async () => {
    try {
        const entries = await contentClient.getEntries({
            content_type: "partnerLower",
            select: "fields",
        });
        return entries.items;
    } catch (error) {
        return null; // Handle error appropriately
    }
});

// Fetch a single partner lower entry by ID
export const getSinglePartnerLower = createAsyncThunk(
    "partnerLower/getSinglePartnerLower",
    async (id) => {
        try {
            const entry = await contentClient.getEntry(id);
            return entry;
        } catch (error) {
            return null; // Handle error appropriately
        }
    }
);

// Create the slice
const PartnerLowerSlice = createSlice({
    name: "partnerLower",
    initialState: { partnerLower: null, singlePartnerLower: null },
    extraReducers: (builder) => {
        builder
            // ALL PARTNERS LOWER
            .addCase(getPartnerLower.pending, (state) => {
                state.partnerLower = null;
            })
            .addCase(getPartnerLower.fulfilled, (state, action) => {
                state.partnerLower = action.payload;
            })
            .addCase(getPartnerLower.rejected, (state) => {
                state.partnerLower = null;
            })
            // SINGLE PARTNER LOWER
            .addCase(getSinglePartnerLower.pending, (state) => {
                state.singlePartnerLower = null;
            })
            .addCase(getSinglePartnerLower.fulfilled, (state, action) => {
                state.singlePartnerLower = action.payload;
            })
            .addCase(getSinglePartnerLower.rejected, (state) => {
                state.singlePartnerLower = null;
            });
    },
});

export const authActions = PartnerLowerSlice.actions;
export default PartnerLowerSlice; // Don't forget to export the reducer