import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import contentClient from "../../config/Sporting";

export const getSocial = createAsyncThunk("social/getSocial", async () => {
	try {
		const entries = await contentClient.getEntries({
			content_type: "socialLinks",
		});
		return entries.items;
	} catch (error) {
		return null;
	}
});

const Socialslice = createSlice({
	name: "social",
	initialState: { social: null },
	extraReducers: {
		[getSocial.pending]: (state) => {
			state.social = null;
		},
		[getSocial.fulfilled]: (state, action) => {
			state.social = action.payload;
		},
		[getSocial.rejected]: (state) => {
			state.social = null;
		},
	},
});

export const authActions = Socialslice.actions;
export default Socialslice;
