import React from "react";
import sl from "../assets/images/loader.json";
import Lottie from "lottie-react";
const Loader = () => {
	return (
		<div className="lottie_loader">
			<Lottie animationData={sl} />
		</div>
	);
};

export default Loader;
