import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import contentClient from "../../config/Sporting";

export const getPartner = createAsyncThunk("partner/getPartner", async () => {
	try {
		const entries = await contentClient.getEntries({
			content_type: "partners",
			select: "fields",
		});
		return entries.items;
	} catch (error) {
		return null;
	}
});

export const getSinglePartner = createAsyncThunk(
	"partner/getSinglePartner",
	async (id) => {
		try {
			const entries = await contentClient.getEntry(id);
			return entries;
		} catch (error) {
			return null;
		}
	}
);

const PartnerSlice = createSlice({
	name: "partner",
	initialState: { partner: null, singlePartner: null },
	extraReducers: {
		// ALL PARTNERS
		[getPartner.pending]: (state) => {
			state.partner = null;
		},
		[getPartner.fulfilled]: (state, action) => {
			state.partner = action.payload;
		},
		[getPartner.rejected]: (state) => {
			state.partner = null;
		},

		// SINGLE PARTNER
		[getSinglePartner.pending]: (state) => {
			state.singlePartner = null;
		},
		[getSinglePartner.fulfilled]: (state, action) => {
			state.singlePartner = action.payload;
		},
		[getSinglePartner.rejected]: (state) => {
			state.singlePartner = null;
		},
	},
});

export const authActions = PartnerSlice.actions;
export default PartnerSlice;
