import React from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Error404 = ({ setError }) => {
	useEffect(() => {
		setError(true);
		return () => {
			setError(false);
		};
	}, [setError]);
	const navigate = useNavigate();
	return (
		<>
			<div className="error-container">
				<div className="error">
					<div className="error-banner">
						<img
							src="https://res.cloudinary.com/dkbxja6tf/image/upload/v1666986288/Assets/trophy_pdejks.jpg"
							alt=""
						/>
					</div>
					<div className="error-message">
						<h1>PAGE NOT FOUND</h1>
						<p>
							Unfortunately, the page you were trying to view may have been
							moved, you may have typed in the wrong address, or if the page was
							hosted on our old website it may now no longer exist.
						</p>
						<p className="small">
							Please use the website menu or the search tool at the top of the
							page to find the information or content you are looking for. If
							you want the very latest news, videos, match info and other cool
							stuff, you should find it on our homepage.
						</p>
						<button onClick={() => navigate(-1)}>
							Visit Sporting Homepage
						</button>
					</div>
				</div>
			</div>
		</>
	);
};

export default Error404;
