import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import contentClient from "../../config/Sporting";

export const getSearch = createAsyncThunk("search/getSearch", async (tag) => {
	try {
		const entries = await contentClient.getEntries({
			query: tag,
		});
		return entries.items;
	} catch (error) {
		return null;
	}
});

const SearchSlice = createSlice({
	name: "search",
	initialState: { searchResult: null },
	extraReducers: {
		[getSearch.pending]: (state) => {
			state.searchResult = null;
		},
		[getSearch.fulfilled]: (state, action) => {
			state.searchResult = action.payload;
		},
		[getSearch.rejected]: (state) => {
			state.searchResult = null;
		},
	},
});

export const authActions = SearchSlice.actions;
export default SearchSlice;
