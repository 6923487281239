import { createSlice } from "@reduxjs/toolkit";
const authSlice = createSlice({
  name: "auth",
  initialState: { isLoggedin: false, user: {} },
  reducers: {
    userLogin(state) {
      state.isLoggedin = true;
    },
    userData(state, { payload }) {
      state.user = payload;
    },
    userLogOut(state) {
      state.isLoggedin = false;
      state.user = null;
    },
  },
});
export const { userLogin, userLogOut, userData } = authSlice.actions;

export const authActions = authSlice.actions;
export default authSlice;
