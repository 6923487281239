import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";
import contentClient from "../../config/Sporting";

export const getAllNews = createAsyncThunk("news/getAllNews", async () => {
	try {
		const entries = await contentClient.getEntries({
			"sys.contentType.sys.id[in]": "gallery,news,sportingTv",
		});
		return entries.items?.sort(
			(a, b) => moment(b?.fields?.date) - moment(a?.fields?.date)
		);
	} catch (error) {
		return null;
	}
});

export const getLandingNews = createAsyncThunk(
	"news/getLandingNews",
	async () => {
		try {
			const entries = await contentClient.getEntries({
				"sys.contentType.sys.id[in]": "gallery,news,sportingTv",
				// limit: 6,
			});
			const filteredItems = entries?.items?.filter((ee) => ee.fields?.featured);
			return filteredItems?.sort(
				(a, b) => moment(b?.fields?.date) - moment(a?.fields?.date)
			);
		} catch (error) {
			return null;
		}
	}
);

export const getPlayerNews = createAsyncThunk(
	"news/getPlayerNews",
	async (tag) => {
		try {
			const entries = await contentClient.getEntries({
				content_type: "news",
				select: ["metadata", "fields"],
				"metadata.tags.sys.id[in]": tag,
				limit: 3,
			});
			return entries.items;
		} catch (error) {
			return null;
		}
	}
);

export const getSingleNews = createAsyncThunk(
	"news/getSingleNews",
	async (id) => {
		try {
			const entries = await contentClient.getEntry(id);
			return entries;
		} catch (error) {
			return null;
		}
	}
);

const NewsSlice = createSlice({
	name: "news",
	initialState: {
		allNews: null,
		news: null,
		playerNews: null,
		singleNews: null,
	},
	extraReducers: {
		// LANDING NEWS
		[getAllNews.pending]: (state) => {
			state.allNews = null;
		},
		[getAllNews.fulfilled]: (state, action) => {
			state.allNews = action.payload;
		},
		[getAllNews.rejected]: (state) => {
			state.allNews = null;
		},
		// LANDING NEWS
		[getLandingNews.pending]: (state) => {
			state.news = null;
		},
		[getLandingNews.fulfilled]: (state, action) => {
			state.news = action.payload;
		},
		[getLandingNews.rejected]: (state) => {
			state.news = null;
		},

		// PLAYER NEWS
		[getPlayerNews.pending]: (state) => {
			state.playerNews = null;
		},
		[getPlayerNews.fulfilled]: (state, action) => {
			state.playerNews = action.payload;
		},
		[getPlayerNews.rejected]: (state) => {
			state.playerNews = null;
		},
		// SINGLE NEWS
		[getSingleNews.pending]: (state) => {
			state.singleNews = null;
		},
		[getSingleNews.fulfilled]: (state, action) => {
			state.singleNews = action.payload;
		},
		[getSingleNews.rejected]: (state) => {
			state.singleNews = null;
		},
	},
});

export const authActions = NewsSlice.actions;
export default NewsSlice;
