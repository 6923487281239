import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import contentClient from "../../config/Sporting";

export const getAchieve = createAsyncThunk("achieve/getAchieve", async () => {
	try {
		const entries = await contentClient.getEntries({
			content_type: "statistics",
			select: "fields",
		});
		return entries;
	} catch (error) {
		return null;
	}
});

const AchieveSlice = createSlice({
	name: "achieve",
	initialState: { achieve: null },
	extraReducers: {
		[getAchieve.pending]: (state) => {
			state.achieve = null;
		},
		[getAchieve.fulfilled]: (state, action) => {
			state.achieve = action.payload;
		},
		[getAchieve.rejected]: (state) => {
			state.achieve = null;
		},
	},
});

export const authActions = AchieveSlice.actions;
export default AchieveSlice;
